#ocr-text-display {
    display: flex;

    textarea {
        height: 75px;
        width: 500px;
    }
    button {
        margin-left: 5px;
    }
}


.modal-grid {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr;

    .attached-item {
        border:0 !important;

        .icon-search,
        .search-progress {
            border:1px solid $grey-border !important;

            .progress-indicator {
                font-size:0.8em;
                margin-left:3px;
            }
        }
        .search-progress {
            width:29px;
        }
        input {
            border:1px solid $grey-border !important;
            border-left:0 !important;
        }
    }
    button {
        display: inline-block;
        margin-left:5px;
    }
    .brd-lft {
        align-items: start !important;
        border-left:1px solid $grey-border;
        padding-left:20px;

        span {
            color: $orange-primary;
            display: block;
            margin-top:15px;
        }
    }
}

.notes-message {
    font-size:12px;
    font-weight: 600;
    padding:10px 10px 0px 10px;

    &.warning {
        i {
            background:$orange-light;
            color:$white;
            font-size:1.25em;
            margin-right:10px;
            padding:3px;
            position: relative;
            top:2px;
        }
    }

    .sub-note {
        color: $grey-medium-light;
        display: inline-block;
        padding-left: 20px;

        i {
            background: $grey-medium-light !important;
        }
    }

    &.primary {
        color:$blue-primary;

        i {
            background:$blue-primary;
            color:$white;
            font-size:1.25em;
            margin-right:10px;
            padding:3px;
            position: relative;
            top:2px;
        }
    }
}

.ocr-results-modal-wrapper {
    .input-label {
        background-color: $grey-super-light;
        border: 1px solid $grey-light;
        border-bottom: 0;
        color: $grey-dark;
        font-family: "Raleway";
        font-size: 0.65rem !important;
        font-weight:700;
        letter-spacing: 2px;
        margin-bottom: 0 !important;
        max-width:250px;
        padding: 4px;
        text-transform:uppercase;

    }
    .input-control:last-child {
        .input-row {
            padding-bottom:0 !important;
        }
    }
}