.button-menu {
    border-top: 1px solid $transparent-15;
    box-shadow: $toolbar-shadow;
    display: none;
    padding: 4px;
    position: absolute;
    left: 0;
    top: 32px;
    width: 100%;
    z-index: 99;

    &.active {
        display: block;
    }

    &.blue {
        background: $blue-bright;
    }
    &.green {
        background: $green-bright;
    }
    &.grey {
        background: $grey-primary;
    }
    &.orange {
        background: $orange-bright;
    }
    &.pink {
        background: $pink-bright;
    }
    &.purple {
        background: $purple-bright;
    }
    &.red {
        background: $red-bright;
    }
    &.yellow {
        background: $yellow-bright;
    }

    a {
        border-left: 2px solid transparent;
        color: $white;
        cursor: pointer;
        display: block;
        padding: 6px 3px;
        transition: $default-transition;

        &:hover {
            border-left-color: $white;
        }
    }

    span {
        border-bottom: 1px solid $transparent-10;
        display: block;
        height: 1px;
        margin: 4px 0;
        width: 100%;
    }
}

.retry-arch {
    left: 235px;
    position: absolute;
    padding-bottom: 15px;
}
