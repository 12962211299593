.main-content-center {
    .left-half {
        justify-content: flex-end;
        overflow: hidden;

        .settings-panel {
            z-index: 100;
        }
    }

    .row-actions {
        padding: 0.4rem 0.4rem 2.4rem 0.4rem;
        border-top: 1px solid $grey-border;
        box-shadow: $popup-shadow;
        // z-index: 10;
        background-color: white;
        flex-shrink: 0;
        grid-gap: 0.5rem;

        button {
            height: 2rem;
        }
    }

    .table-wrapper {
        position: relative;
        height: 100%;
    }
}

.client-matches-search {
    padding: 10px 15px;
}

.matches-as-parsed {
    padding: 0 15px 5px 15px;
}
