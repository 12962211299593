div.pending_bor .handle.pad-right-05,
div.pending_bor {
    position: unset;
    color: black;
    display: flex;
}

.suspected-dupes.flex.ai_c.jc_sb.pending_bor_panel {
    display: flex;
    overflow: unset;
}